import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const ScenicAreaInfo = () => import('@/entities/scenic-area-info/scenic-area-info.vue');
// prettier-ignore
const ScenicAreaInfoUpdate = () => import('@/entities/scenic-area-info/scenic-area-info-update.vue');
const ScenicAreaInfoSecret = () => import('@/entities/scenic-area-info/scenic-area-info-secret.vue');
// prettier-ignore
const ScenicAreaInfoDetails = () => import('@/entities/scenic-area-info/scenic-area-info-details.vue');
// prettier-ignore
const ScenicAreaCameraCaptureRule = () => import('@/entities/scenic-area-camera-capture-rule/scenic-area-camera-capture-rule.vue');
// prettier-ignore
const ScenicAreaCameraCaptureRuleUpdate = () => import('@/entities/scenic-area-camera-capture-rule/scenic-area-camera-capture-rule-update.vue');
// prettier-ignore
const ScenicAreaCameraCaptureRuleDetails = () => import('@/entities/scenic-area-camera-capture-rule/scenic-area-camera-capture-rule-details.vue');
// prettier-ignore
const ScenicAreaCameraInfo = () => import('@/entities/scenic-area-camera-info/scenic-area-camera-info.vue');
// prettier-ignore
const ScenicAreaCameraInfoUpdate = () => import('@/entities/scenic-area-camera-info/scenic-area-camera-info-update.vue');
// prettier-ignore
const ScenicAreaCameraInfoDetails = () => import('@/entities/scenic-area-camera-info/scenic-area-camera-info-details.vue');
// prettier-ignore
const MemberInfo = () => import('@/entities/member-info/member-info.vue');
// prettier-ignore
const MemberInfoUpdate = () => import('@/entities/member-info/member-info-update.vue');
// prettier-ignore
const MemberInfoDetails = () => import('@/entities/member-info/member-info-details.vue');
// prettier-ignore
const MemberFaceRecord = () => import('@/entities/member-face-record/member-face-record.vue');
// prettier-ignore
const MemberFaceRecordUpdate = () => import('@/entities/member-face-record/member-face-record-update.vue');
// prettier-ignore
const MemberFaceRecordDetails = () => import('@/entities/member-face-record/member-face-record-details.vue');
// prettier-ignore
const ScenicAreaMemberVisitRecord = () => import('@/entities/scenic-area-member-visit-record/scenic-area-member-visit-record.vue');
// prettier-ignore
const ScenicAreaMemberVisitRecordUpdate = () => import('@/entities/scenic-area-member-visit-record/scenic-area-member-visit-record-update.vue');
// prettier-ignore
const ScenicAreaMemberVisitRecordDetails = () => import('@/entities/scenic-area-member-visit-record/scenic-area-member-visit-record-details.vue');
// prettier-ignore
const ScenicAreaSnapRecord = () => import('@/entities/scenic-area-snap-record/scenic-area-snap-record.vue');
// prettier-ignore
const ScenicAreaSnapRecordUpdate = () => import('@/entities/scenic-area-snap-record/scenic-area-snap-record-update.vue');
// prettier-ignore
const ScenicAreaSnapRecordDetails = () => import('@/entities/scenic-area-snap-record/scenic-area-snap-record-details.vue');
// prettier-ignore
const OrderInfo = () => import('@/entities/order-info/order-info.vue');
// prettier-ignore
const OrderInfoUpdate = () => import('@/entities/order-info/order-info-update.vue');
// prettier-ignore
const OrderInfoDetails = () => import('@/entities/order-info/order-info-details.vue');
// prettier-ignore
const PrintOrderPicInfo = () => import('@/entities/print-order-pic-info/print-order-pic-info.vue');
// prettier-ignore
const PrintOrderPicInfoUpdate = () => import('@/entities/print-order-pic-info/print-order-pic-info-update.vue');
// prettier-ignore
const PrintOrderPicInfoDetails = () => import('@/entities/print-order-pic-info/print-order-pic-info-details.vue');
// prettier-ignore
const TransRecord = () => import('@/entities/trans-record/trans-record.vue');
// prettier-ignore
const TransRecordUpdate = () => import('@/entities/trans-record/trans-record-update.vue');
// prettier-ignore
const TransRecordDetails = () => import('@/entities/trans-record/trans-record-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'scenic-area-info',
      name: 'ScenicAreaInfo',
      component: ScenicAreaInfo,
      meta: { authorities: [Authority.SUPER_ADMIN] },
    },
    {
      path: 'scenic-area-info/new',
      name: 'ScenicAreaInfoCreate',
      component: ScenicAreaInfoUpdate,
      meta: { authorities: [Authority.SUPER_ADMIN] },
    },
    {
      path: 'scenic-area-info/:scenicAreaInfoId/edit',
      name: 'ScenicAreaInfoEdit',
      component: ScenicAreaInfoUpdate,
      meta: { authorities: [Authority.SUPER_ADMIN] },
    },
    {
      path: 'scenic-area-info/:scenicAreaInfoId/secret',
      name: 'ScenicAreaInfoSecret',
      component: ScenicAreaInfoSecret,
      meta: { authorities: [Authority.SUPER_ADMIN] },
    },
    {
      path: 'scenic-area-info/:scenicAreaInfoId/view',
      name: 'ScenicAreaInfoView',
      component: ScenicAreaInfoDetails,
      meta: { authorities: [Authority.SUPER_ADMIN] },
    },
    {
      path: 'scenic-area-camera-capture-rule',
      name: 'ScenicAreaCameraCaptureRule',
      component: ScenicAreaCameraCaptureRule,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-camera-capture-rule/new',
      name: 'ScenicAreaCameraCaptureRuleCreate',
      component: ScenicAreaCameraCaptureRuleUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-camera-capture-rule/:scenicAreaCameraCaptureRuleId/edit',
      name: 'ScenicAreaCameraCaptureRuleEdit',
      component: ScenicAreaCameraCaptureRuleUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-camera-capture-rule/:scenicAreaCameraCaptureRuleId/view',
      name: 'ScenicAreaCameraCaptureRuleView',
      component: ScenicAreaCameraCaptureRuleDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-camera-info',
      name: 'ScenicAreaCameraInfo',
      component: ScenicAreaCameraInfo,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-camera-info/new',
      name: 'ScenicAreaCameraInfoCreate',
      component: ScenicAreaCameraInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-camera-info/:scenicAreaCameraInfoId/edit',
      name: 'ScenicAreaCameraInfoEdit',
      component: ScenicAreaCameraInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-camera-info/:scenicAreaCameraInfoId/view',
      name: 'ScenicAreaCameraInfoView',
      component: ScenicAreaCameraInfoDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-info',
      name: 'MemberInfo',
      component: MemberInfo,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-info/new',
      name: 'MemberInfoCreate',
      component: MemberInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-info/:memberInfoId/edit',
      name: 'MemberInfoEdit',
      component: MemberInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-info/:memberInfoId/view',
      name: 'MemberInfoView',
      component: MemberInfoDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-face-record',
      name: 'MemberFaceRecord',
      component: MemberFaceRecord,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-face-record/new',
      name: 'MemberFaceRecordCreate',
      component: MemberFaceRecordUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-face-record/:memberFaceRecordId/edit',
      name: 'MemberFaceRecordEdit',
      component: MemberFaceRecordUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-face-record/:memberFaceRecordId/view',
      name: 'MemberFaceRecordView',
      component: MemberFaceRecordDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-member-visit-record',
      name: 'ScenicAreaMemberVisitRecord',
      component: ScenicAreaMemberVisitRecord,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-member-visit-record/new',
      name: 'ScenicAreaMemberVisitRecordCreate',
      component: ScenicAreaMemberVisitRecordUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-member-visit-record/:scenicAreaMemberVisitRecordId/edit',
      name: 'ScenicAreaMemberVisitRecordEdit',
      component: ScenicAreaMemberVisitRecordUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-member-visit-record/:scenicAreaMemberVisitRecordId/view',
      name: 'ScenicAreaMemberVisitRecordView',
      component: ScenicAreaMemberVisitRecordDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-snap-record',
      name: 'ScenicAreaSnapRecord',
      component: ScenicAreaSnapRecord,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-snap-record/new',
      name: 'ScenicAreaSnapRecordCreate',
      component: ScenicAreaSnapRecordUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-snap-record/:scenicAreaSnapRecordId/edit',
      name: 'ScenicAreaSnapRecordEdit',
      component: ScenicAreaSnapRecordUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'scenic-area-snap-record/:scenicAreaSnapRecordId/view',
      name: 'ScenicAreaSnapRecordView',
      component: ScenicAreaSnapRecordDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'order-info',
      name: 'OrderInfo',
      component: OrderInfo,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'order-info/new',
      name: 'OrderInfoCreate',
      component: OrderInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'order-info/:orderInfoId/edit',
      name: 'OrderInfoEdit',
      component: OrderInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'order-info/:orderInfoId/view',
      name: 'OrderInfoView',
      component: OrderInfoDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'print-order-pic-info',
      name: 'PrintOrderPicInfo',
      component: PrintOrderPicInfo,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'print-order-pic-info/new',
      name: 'PrintOrderPicInfoCreate',
      component: PrintOrderPicInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'print-order-pic-info/:printOrderPicInfoId/edit',
      name: 'PrintOrderPicInfoEdit',
      component: PrintOrderPicInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'print-order-pic-info/:printOrderPicInfoId/view',
      name: 'PrintOrderPicInfoView',
      component: PrintOrderPicInfoDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'trans-record',
      name: 'TransRecord',
      component: TransRecord,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'trans-record/new',
      name: 'TransRecordCreate',
      component: TransRecordUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'trans-record/:transRecordId/edit',
      name: 'TransRecordEdit',
      component: TransRecordUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'trans-record/:transRecordId/view',
      name: 'TransRecordView',
      component: TransRecordDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
